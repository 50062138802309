import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../containers/Page';
import React, { useEffect } from 'react';
import SearchBar from '../containers/SearchBar';
import List from '../containers/List';
import { useIsFocused } from '@react-navigation/native';
import styled from 'styled-components/native';
import Card from '../presentational/Card';
import Gap from '../presentational/Gap';
import Row from '../presentational/Row';
import Button from '../presentational/Button';
import Col from '../presentational/Col';
import * as DocumentPicker from 'expo-document-picker';
import CustomModal from '../presentational/Modal';
import { FlatList, View } from 'react-native';
import Message from '../presentational/Message';
import { vhToDp, vwToDp } from '../../utilities/general';
import { Platform } from 'react-native';
import ExcelJS from 'exceljs';
import moment from 'moment';
import download from 'downloadjs';
import SelectDropdown from 'react-native-select-dropdown';
import { MaterialIcons } from '@expo/vector-icons';
import Text from '../presentational/Text';

const exportToCSV = async (data, filter, tenant) => {
  const now = new Date();
  const fileName = `Tools_${filter}_${moment().format('MM/DD/YYYY')}.xlsx`;

  return new Promise((resolve, reject) => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Me';
    workbook.created = now;
    workbook.modified = now;

    const worksheet = workbook.addWorksheet('My Sheet', {});

    worksheet.columns = Object.keys(data[0]).map((key) => {
      if (key === 'tenantId') {
        return {
          header: 'tenant',
          key: 'tenant',
        };
      }
      if (key === 'fandFCode' && tenant.id === '11') {
        return {
          header: 'NSN',
          key: 'fandFCode',
        };
      }
      return {
        header: key,
        key,
      };
    });

    data.forEach((user) => {
      const rowData = {
        ...user,
        tenant: tenant.name,
        nextCalibrationTestDueDate:
          user.nextCalibrationTestDueDate == '1900-01-01T00:00:00.000Z'
            ? ''
            : user.nextCalibrationTestDueDate,
        nextPMIDate:
          user.nextPMIDate == '1900-01-01T00:00:00.000Z'
            ? ''
            : user.nextPMIDate,
        loadTestDueDate:
          user.loadTestDueDate == '1900-01-01T00:00:00.000Z'
            ? ''
            : user.loadTestDueDate,
      };
      worksheet.addRow(rowData);
    });

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      let dataMax = column.values.reduce((max, rowVal) => {
        if (rowVal !== null && rowVal.toString().length > max) {
          return rowVal.toString().length;
        }
        return max;
      }, 0);
      column.width = dataMax < 10 ? 10 : dataMax;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        download(
          blob,
          fileName,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const Container = styled.View`
  padding: ${vhToDp(2)}px ${vwToDp(4)}px;
`;

const SRow = styled(Row)`
  align-items: center;
`;

export default function ToolPage({ navigation, ...props }) {
  const isFocused = useIsFocused();
  const {
    tools: { historyModalActive, searchResults },
    status,
    tenant,
  } = useStoreState((state) => state);

  const {
    getToolsThunk,
    upsertBulkToolsThunk,
    setHistoryModalActive,
    resetBrokenToolInputAction,
    getToolCategoryListThunk,
    getOEMStatusListThunk,
    setAlertThunk,
    getLocationsThunk,
  } = useStoreActions((actions) => actions);

  const [filter, setFilter] = React.useState('');

  useEffect(() => {
    if (isFocused) {
      getToolsThunk(0);
      resetBrokenToolInputAction();
      getToolCategoryListThunk();
      getOEMStatusListThunk();
      getLocationsThunk();
    }
  }, [isFocused]);

  return (
    <>
      <Page.Protected {...props}>
        <Container>
          <CustomModal.BrokenTool />
          <CustomModal
            isVisible={historyModalActive}
            title='Upload History'
            onClose={() => {
              setHistoryModalActive(false);
            }}
          >
            <FlatList
              style={{
                height: Platform.OS == 'web' ? '50vh' : '50%',
                width: Platform.OS == 'web' ? '70vh' : '70%',
              }}
              data={status.savedAlerts}
              renderItem={(alert, index) => {
                let Component = Message;
                if (alert.item.type === 'error') Component = Message.Error;
                else if (alert.item.type === 'success')
                  Component = Message.Success;
                return <Component key={index}>{alert.item.message}</Component>;
              }}
            />
          </CustomModal>
          <SRow>
            <Col xs={12} sm={2}>
              <Button fullWidth onPress={() => navigation.navigate('Add Tool')}>
                Add Tool
              </Button>
            </Col>
            {Platform.OS === 'web' && (
              <>
                <Col xs={12} sm={2}>
                  <Button
                    fullWidth
                    onPress={async () => {
                      const document = await DocumentPicker.getDocumentAsync({
                        type: 'json/csv',
                      });
                      const b64 = document.uri.split(',')[1];
                      await upsertBulkToolsThunk({
                        navigation,
                        input: b64,
                      });

                      await getToolsThunk(0);
                    }}
                  >
                    Bulk Tool Upload
                  </Button>
                </Col>
                <Col xs={12} sm={2}>
                  <Button.GetToolSample />
                </Col>
              </>
            )}
            <Col xs={12} sm={2}>
              <Button
                color='#00205B'
                fullWidth
                onPress={async () => {
                  setHistoryModalActive(true);
                }}
              >
                History
              </Button>
            </Col>
            <Col xs={12} sm={2}>
              <Button.ReportBrokenTool navigation={navigation} />
            </Col>
            <Col xs={12} sm={Platform.OS === 'web' ? 2 : 6}>
              <SearchBar.Tools />
            </Col>
          </SRow>
          <Gap />
          <Card
            toolTipText={'How to use the Tool Screen'}
            toolUrl={'https://www.screencast.com/t/htyhv5Jv2J'}
          >
            <List.Tools navigation={navigation} />
          </Card>
        </Container>
      </Page.Protected>
      <View
        style={{
          position: 'absolute',
          right: 100,
          bottom: 40,
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <SelectDropdown
          data={[
            { item: 'All', id: 'all' },
            { item: 'Available', id: 'available' },
            { item: 'Reserved', id: 'reserved' },
            { item: 'Unserviceable', id: 'unserviceable' },
            { item: 'Inspection', id: 'inspection' },
          ]}
          onSelect={(selectedItem, index) => {
            console.log(selectedItem.item);
            setFilter(selectedItem.item);
          }}
          renderButton={(item, isOpened) => {
            return (
              <View
                style={{
                  backgroundColor: '#00205B',
                  width: 180,
                  height: 47,
                  flexDirection: 'row',
                  alignSelf: 'center',
                  alignContent: 'flex-start',
                  alignText: 'flex-start',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    color: '#FFFFFF',
                    fontSize: 16,
                    fontFamily: 'Barlow_400Regular',
                  }}
                >
                  {item?.item || 'Select Category'}
                </Text>
                <MaterialIcons
                  name='keyboard-arrow-down'
                  size={24}
                  color='white'
                />
              </View>
            );
          }}
          renderItem={(item, index, isSelected) => {
            return (
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  paddingHorizontal: 12,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingVertical: 8,
                  borderBottomWidth: Platform.OS == 'web' ? 0.1 : 0.3,
                  borderBottomColor: '#c5c5c5',
                }}
              >
                <Text
                  style={{
                    flex: 1,
                    color: '#00205b',
                    fontFamily: 'Barlow_400Regular',
                  }}
                >
                  {item?.item}
                </Text>
              </View>
            );
          }}
        />

        {filter !== '' && (
          <Button
            style={{
              marginLeft: 10,
            }}
            onPress={() => {
              // if there are no tools that fit the requested filter, throw a warning
              if (
                filter !== 'All' &&
                searchResults.filter((item) => item.statusDescription == filter)
                  .length == 0
              ) {
                setAlertThunk({
                  type: 'error',
                  message:
                    'There are no tools that fit the requested filter. Please try again.',
                });
                return;
              } else {
                exportToCSV(
                  filter == '' || filter == 'All'
                    ? searchResults
                    : searchResults.filter(
                        (item) => item.statusDescription == filter
                      ),
                  filter,
                  tenant?.input
                );
              }
            }}
          >
            Generate Tool CSV
          </Button>
        )}
      </View>
    </>
  );
}
